<template>
  <main class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
    <div class="flex justify-between items-center my-10">
      <h2
        class="uppercase text-lg md:text-2xl font-semibold text-gray-700 dark:text-gray-200"
      >
        Transactions
      </h2>
    </div>

    <div class="bg-white dark:bg-gray-800 mt-10">
      <filter-button id="filters">
        <template v-slot:search>
          <div class="w-56 flex md:ml-0 bg-white dark:bg-gray-800">
            <label for="search_field" class="sr-only">Search</label>
            <div
              class="relative w-full text-gray-400 focus-within:text-gray-600"
            >
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center bg-white dark:bg-gray-800"
              >
                <svg
                  class="h-5 w-5 stroke-current"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.9999 21L16.6499 16.65"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div
                v-if="filter.searchByName"
                class="absolute inset-y-0 right-0 focus:outline-none flex items-center"
              >
                <button type="button" @click="clearSearchInput">
                  <svg
                    class="h-5 w-5 stroke-current"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.7612 6.7998L6.76123 18.7998"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.76123 6.7998L18.7612 18.7998"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <input
                autocomplete="off"
                @keyup="gettransactions()"
                v-model="filter.searchByName"
                id="search_field"
                class="bg-white dark:bg-gray-800 block h-full w-full border-0 py-2 pl-8 pr-3 text-gray-900 dark:text-grey-H-400 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search"
                type="text"
                name="search"
              />
            </div>
          </div>
        </template>
        <div
          id="filters"
          class="hidden mt-8 grid grid-cols-2 gap-8 max-w-lg mx-auto text-black dark:text-grey-H-400"
        >
          <div class="flex items-center space-x-2">
            <div class="">Product</div>
            <div class="w-full relative">
              <button
                id="productFilterButton"
                @click="toggleProduct"
                class="bg-white dark:bg-gray-800 border border-grey-H-100 dark:border-grey-H-700 px-2 py-1 w-full focus:outline-none flex justify-between items-center"
              >
                <span>
                  {{ productName }}
                </span>
                <span>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13 1L7 6L1 1" stroke="#7E7E7E" stroke-width="2" />
                  </svg>
                </span>
              </button>
              <div
                class="absolute z-20 border bg-white dark:bg-gray-800  border-t-0 border-grey-H-100 dark:border-grey-H-700 w-full p-2 divide-y-2 h-32 overflow-auto -mt-px"
                v-if="toggleProductBox"
              >
                <button
                  @click="clearFilterTheProduct()"
                  class="w-full text-left py-1 flex items-center justify-between focus:outline-none "
                >
                  <span>All Products</span>
                </button>

                <button
                  @click="
                    filterTheProduct(product.productId, product.productName)
                  "
                  :key="index"
                  v-for="(product, index) in products"
                  :id="product.productId"
                  class="w-full text-left py-1 flex items-center justify-between focus:outline-none"
                >
                  <span>{{ product.productName }}</span>
                  <span v-if="isProductSelected(product.productId)">
                    <svg
                      class="text-green-600 stroke-current w-4 h-4"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.9373 6.61938L9.93726 17.6194L4.93726 12.6194"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="flex items-center space-x-2">
            <div class="">Producer</div>
            <div class="w-full relative">
              <button
                id="producerFilterButton"
                @click="toggleProducer"
                class="bg-white dark:bg-gray-800 border border-grey-H-100 dark:border-grey-H-700 px-2 py-1 w-full focus:outline-none flex justify-between items-center"
              >
                <span>
                  {{ ProducerName }}
                </span>
                <span>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13 1L7 6L1 1" stroke="#7E7E7E" stroke-width="2" />
                  </svg>
                </span>
              </button>
              <div
                class="absolute z-20 bg-white dark:bg-gray-800 border border-grey-H-100 dark:border-grey-H-700 border-t-0 w-full p-2 divide-y-2 h-32 overflow-auto -mt-px"
                v-if="toggleProducerBox"
              >
                <button
                  @click="clearFilterTheProducer"
                  class="w-full text-left py-1 flex items-center justify-between focus:outline-none"
                >
                  <span>All Producers</span>
                </button>

                <button
                  @click="
                    filterTheProducer(
                      producer.producerId,
                      producer.producerName
                    )
                  "
                  :key="index"
                  v-for="(producer, index) in producers"
                  :id="producer.producerId"
                  class="w-full text-left py-1 flex items-center justify-between focus:outline-none"
                >
                  <span>{{ producer.producerName }}</span>
                  <span v-if="isProducerSelected(producer.producerId)">
                    <svg
                      class="text-green-600 stroke-current w-4 h-4"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.9373 6.61938L9.93726 17.6194L4.93726 12.6194"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="flex items-center space-x-2">
            <span class="">From</span>
            <input
              v-model="filter.from"
              type="date"
              class="w-full bg-white dark:bg-gray-800 border border-grey-H-100 dark:border-grey-H-700"
            />
          </div>

          <div class="flex items-center space-x-2">
            <span class="">To</span>
            <input
              :disabled="filter.from != null ? false : true"
              v-model="filter.to"
              type="date"
              class="w-full bg-white dark:bg-gray-800 border border-grey-H-100 dark:border-grey-H-700"
              @change="filterTheDate()"
            />
          </div>
        </div>
      </filter-button>
    </div>

    <div
      class="bg-gray-50 dark:text-gray-400 dark:bg-gray-800 rounded-lg shadow-md relative mt-6"
    >
      <div v-if="total > 0" class="w-full whitespace-no-wrap">
        <div
          class="flex justify-between items-center pt-4 pb-2 text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
        >
          <span class="w-1/2 md:w-3/12 px-4 py-3">Product</span>
          <span class="w-1/2 md:w-2/12 px-4 py-3">Producer</span>
          <span class="w-1/2 md:w-2/12 px-4 py-3">Price</span>
          <span class="w-1/2 md:w-2/12 px-4 py-3">Quantity</span>
          <span class="w-1/2 md:w-2/12 px-4 py-3">Orders</span>
          <span class="w-1/2 md:w-2/12 px-4 py-3">Date</span>
          <span class="w-1/2 md:w-1/12 px-4 py-3"></span>
        </div>
        <div class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
          <div
            :key="index"
            v-for="(transaction, index) in transactions"
            class="flex justify-between items-center text-gray-700 dark:text-gray-400"
          >
            <div
              class="w-1/2 md:w-3/12 px-4 py-3 text-sm flex items-center space-x-2"
            >
              <div
                class="relative hidden rounded-full md:block overflow-hidden"
              >
                <img
                  :src="transaction.productImage"
                  class="w-8 h-8 object-cover"
                />
                <div
                  class="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                ></div>
              </div>
              <p class="font-semibold">{{ transaction.productName }}</p>
            </div>

            <div class="w-1/2 md:w-2/12 px-4 py-3 text-sm">
              <div>
                <p class="font-semibold">{{ transaction.producerName }}</p>
              </div>
            </div>
            <div class="w-1/2 md:w-2/12 px-4 py-3 text-sm">
              <div>
                <p class="font-semibold">{{ transaction.productPrice }}</p>
              </div>
            </div>
             <div class="w-1/2 md:w-2/12 px-4 py-3 text-sm">
              <div>
                <p class="font-semibold">{{ transaction.qty }}</p>
              </div>
            </div>
            <div class="w-1/2 md:w-2/12 px-4 py-3 text-sm">
              <p class="font-semibold">{{ transaction.orderCode }}</p>
            </div>
            <span class="w-1/2 md:w-2/12 px-4 py-3 text-sm">
              <p class="font-semibold">{{ transaction.createdOn }}</p>
            </span>
            <div class="w-1/2 md:w-1/12 px-4 py-3 text-sm"></div>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
        >
          <div class="w-1/4 text-grey-H-600 text-sm font-bold">
            Total:
            <span class="text-grey-H-900 font-medium">
              {{ totalprice }}LYD
            </span>
          </div>
          <div class="w-2/4 flex justify-center">
            <el-pagination
              background
              :page-size="filter.pageSize"
              layout="prev, pager, next"
              :total="total"
              :current-page.sync="filter.pageNo"
              @current-change="PageChanged"
              class="pagination justify-content-center pagination-sm"
            >
            </el-pagination>
          </div>
          <div class="w-1/4"></div>
        </div>
      </div>
      <div
        v-else
        id="emptyState"
        class="text-gray_5 text-sm text-center h-96 flex items-center justify-center"
      >
        <div
          v-if="loading"
          id="testtest"
          class="h-64 flex justify-center items-center"
        >
          <svg
            class="w-10 h-w-10 rounded-full"
            viewBox="0 0 38 38"
            stroke="#fff"
          >
            <g fill="none">
              <g transform="translate(1 1)" stroke-width="2">
                <circle
                  stroke="#E1E7EC"
                  stroke-opacity=".5"
                  cx="18"
                  cy="18"
                  r="18"
                />
                <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>

        <div
          v-else
          class="h-64 flex justify-center items-center w-full bg-white dark:bg-gray-800"
        >
          There are no transaction yet ...
        </div>
      </div>
    </div>

    <div class="bg-white dark:bg-gray-800 px-12 py-12 mt-6">
      <p class="font-medium text-base text-gray-900 dark:text-gray-200">
        Sales Summary
      </p>

      <div class="mt-7 grid grid-cols-3 gap-5">
        <div
          class="border border-gray-200 dark:border-gray-600 text-center py-8"
        >
          <p class="text-3xl text-gray-900 dark:text-gray-300 font-bold">
            {{ totalSales }}
          </p>
          <p class="text-grey-H-600 text-base font-medium mt-3">TOTAL SALES</p>
        </div>
        <div
          class="border border-gray-200 dark:border-gray-600 text-center py-8"
        >
          <p class="text-3xl text-gray-900 dark:text-gray-300 font-bold">
            {{ companyRevenue }}
          </p>
          <p class="text-grey-H-600 text-base font-medium mt-3">
            COMPANY REVENUE
          </p>
        </div>
        <div
          class="border border-gray-200 dark:border-gray-600 text-center py-8"
        >
          <p class="text-3xl text-gray-900 dark:text-gray-300 font-bold">
            {{ designerRevenue }}
          </p>
          <p class="text-grey-H-600 text-base font-medium mt-3">
            DESIGNERS REVENUE
          </p>
        </div>
      </div>
    </div>

    <!-- Start making sure delete it  -->
    <div v-show="makingSureDeleteIt">
      <button
        @click="makingSureDeleteIt = false"
        class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
      >
        <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
          <div class="flex justify-between items-start">
            <div class="text-left">
              <p class="text-2xl font-bold">
                Are you sure to delete {{ prepareDeleteproductName }} ?
              </p>
              <p class="text-sm mt-2">It will be permanently deleted.</p>
            </div>

            <button
              @click="makingSureDeleteIt = false"
              class="focus:outline-none"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="mt-10 space-x-4 flex justify-start">
            <button
              @click="deleteButton()"
              class="uppercase bg-black text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Delete
            </button>
            <button
              @click="makingSureDeleteIt = false"
              class="uppercase bg-white text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-black border border-black rounded focus:outline-none hover:shadow-xl transform duration-700"
            >
              Cancel
            </button>
          </div>
        </div>
      </button>
    </div>
    <!-- End making sure delete it  -->

    <!-- Start Delete Status -->
    <div v-show="deleteStatus">
      <button
        @click="deleteStatus = false"
        class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
      >
        <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
          <div class="flex justify-end">
            <button @click="deleteStatus = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="38" stroke="black" stroke-width="4" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.3149 28.7293C57.2486 29.6808 57.225 31.2002 56.2621 32.123L36.2264 51.323C35.2843 52.2257 33.7871 52.2257 32.8451 51.323L23.7379 42.5957C22.775 41.673 22.7514 40.1535 23.6851 39.202C24.6188 38.2504 26.1563 38.227 27.1192 39.1498L34.5357 46.2569L52.8808 28.677C53.8437 27.7543 55.3812 27.7777 56.3149 28.7293Z"
                fill="black"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">Delete Successfuly</p>
            <p class="text-sm mt-2">Permanently deleted</p>
          </div>

          <div class="mt-10 flex justify-center">
            <button
              @click="deleteStatus = false"
              class="bg-black text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Close
            </button>
          </div>
        </div>
      </button>
    </div>
    <!-- End Delete Status -->
  </main>
</template>

<script>
export default {
  created() {},
  mounted() {
    this.gettransactions();
    this.GetProducer();
    this.GetProducts();
  },

  data() {
    return {
      totalSales: "",
      companyRevenue: "",
      designerRevenue: "",

      filter: {
        pageNo: 1,
        pageSize: 10,
        from: null,
        to: null,
        filterTheProductArray: "",
        filterTheProducerArray: "",
        searchByName: "",
      },
      products: [],
      producers: [],
      data: "",
      loading: true,
      showFilter: true,
      showPagination: false,
      total: 0,
      totalRecords: 0,

      CoachesQuery: "",
      displayClearButton: false,

      Coaches: {},

      transactions: [],
      title: null,

      toggleProductBox: false,
      productName: "All Products",

      toggleProducerBox: false,
      ProducerName: "All Producers",
      makingSureDeleteIt: false,
      deleteStatus: false,
      prepareDeleteproductName: "",
      prepareDeletetransactionId: "",
      prepareDeleteReviewsIndex: "",
      totalprice: "",
    };
  },
  watch: {},
  methods: {
    prepareDelete(productName, transactionId, index) {
      this.prepareDeleteproductName = productName;
      this.prepareDeletetransactionId = transactionId;
      this.prepareDeleteReviewsIndex = index;
      this.makingSureDeleteIt = true;
    },

    deleteButton() {
      this.loading = true;

      this.$http.transactionsService
        .DeleteReview(this.prepareDeletetransactionId)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.Successed = true;
          this.addSuccessed = res.data.response;
          this.productReviews.splice(this.prepareDeleteReviewsIndex, 1);

          this.deleteStatus = true;
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
            this.Successed = false;
            this.addErorr = err.response.data.response;
          }, 1000);
        });
    },

    filterTheDate() {
      this.gettransactions();
    },

    isProducerSelected(id) {
      if (this.filter.filterTheProducerArray == id) {
        return true;
      } else {
        return false;
      }
    },

    filterTheProducer(id, ProducerName) {
      this.filter.filterTheProducerArray = id;
      this.ProducerName = ProducerName;
      this.toggleProducer();
      this.gettransactions();
    },
    clearFilterTheProducer() {
      this.filter.filterTheProducerArray = "";
      this.ProducerName = "All Producers";
      this.toggleProducer();
      this.gettransactions();
    },
    toggleProducer() {
      this.toggleProducerBox = !this.toggleProducerBox;
    },

    isProductSelected(id) {
      if (this.filter.filterTheProductArray == id) {
        return true;
      } else {
        return false;
      }
    },

    filterTheProduct(id, productName) {
      this.filter.filterTheProductArray = id;
      this.productName = productName;
      this.toggleProduct();
      this.gettransactions();

      // if (this.filterTheProducArray.includes(id)) {
      //     this.filterTheProducArray.splice(
      //         this.filterTheProducArray.indexOf(id),
      //         1
      //     );
      // } else {
      //     this.filterTheProducArray.push(id);
      // }
      // this.pharmacy.payment_methods = this.filterTheProducArray;
    },
    clearFilterTheProduct() {
      this.filter.filterTheProductArray = "";
      this.productName = "All Products";
      this.toggleProduct();
      this.gettransactions();
    },
    toggleProduct() {
      this.toggleProductBox = !this.toggleProductBox;
    },

    PageChanged(pageNo) {
      this.filter.pageNo = pageNo;
      this.gettransactions();
    },


    clearSearchInput() {
      this.filter.searchByName = "";
      this.gettransactions();
    },

    gettransactions() {
      this.loading = true;
      this.$http.transactionsService
        .GetTransactions(this.filter)
        .then((res) => {
          this.data = res.data;
          this.transactions = res.data.result.transactionsInfo;
          this.totalSales = res.data.result.totalSales;
            this.designerRevenue = res.data.result.designerSales;
            this.companyRevenue = res.data.result.companySales;
          this.total = res.data.result.total;
          this.totalprice = res.data.result.totalprice;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          console.log(err);
        });
    },
    GetProducer() {
      this.$http.lookupService
        .GetProducers()
        .then((res) => {
          this.data = res.data;
          this.producers = res.data.result.producers;
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    GetProducts() {
      this.$http.lookupService
        .GetProducts()
        .then((res) => {
          this.data = res.data;
          this.products = res.data.result.products;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #171122;
  color: #fff;
}
</style>
